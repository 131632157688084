<template>
    <div ref="formWidgetRef" class="widget-file">
        <div class="widget-file-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }} <span v-if="widgetRef.validationProperties.required">*</span></label
            >
            <div class="block">
                <UploadField
                    accept="*/*"
                    v-model:uploadedFile="widgetRef.runtimeProperties.data"
                    v-slot="slotProps"
                    class="widget-file-uploader"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                >
                    <div v-if="isFile && slotProps.file.previewUrl" class="widget-file-preview">
                        <div class="widget-file-placeholder"><img src="/assets/icons/file.svg" class="icon-normal" /></div>
                    </div>
                    <div v-else-if="isString" class="widget-file-preview beta">
                        <a :href="widgetRef.runtimeProperties.data" />
                    </div>
                    <div v-else class="widget-file-browse">
                        <div class="widget-file-placeholder">
                            <div class="flex justify-center items-center p-4 border border-solid border-neutral-normal-light">
                                <img src="/assets/icons/file-upload.svg" class="icon-large" />
                            </div>
                            <div class="flex flex-col items-center">
                                <p><span class="text-green-normal underline">Selezione il file</span> da caricare.</p>
                                <p>(max 5mb?)</p>
                            </div>
                        </div>
                    </div>
                </UploadField>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

// import { useLoggerStore } from '../../../stores/loggerStore';

import UploadField from '../../lib/UploadField.vue';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = ['update:widget'];

const widgetRef = useVModel(props, 'widget', emit);
const formWidgetRef = ref();

const isFile = computed(() => {
    return widgetRef.value.runtimeProperties.data instanceof File;
});
const isString = computed(() => {
    return typeof widgetRef.value.runtimeProperties.data == 'string';
});
// const uploadedFile = ref({});

// if (widgetRef.value.runtimeProperties.data != null) {
//     widgetRef.value.runtimeProperties.data = [];
// }

// uploadedFile.value.fileType = 'image';
// uploadedFile.value.fileUrl = '/assets/images/Il-Diamante-Blu.jpg';
// uploadedFile.value.fileName = 'Il-Diamante-Blu';

// const handleFileUploading = async (fileToUpload) => {
//     // add your fileuploading logic to server and set data to the uploadedFile
//     uploadedFile.value.fileType = 'file';
//     uploadedFile.value.fileUrl = '/assets/images/Il-Diamante-Blu.jpg';
//     uploadedFile.value.fileName = fileToUpload.name;

//     await new Promise((resolve) => setTimeout(resolve, 2000));
// };
</script>

<style lang="postcss" scoped>
.widget-file {
    @apply border-0  w-full;
}
.widget-file-uploader {
    @apply w-full justify-center;
    @apply border border-solid border-neutral-normal-light p-5;
}
.widget-file-browse {
    @apply p-1;
}
.widget-file-placeholder {
    @apply flex flex-col gap-4 items-center text-stone-400;

    img {
        @apply text-stone-400;
    }
}
.widget-file-item {
    @apply flex flex-col;
}
</style>
