<template>
    <Modal modalWidth="800px" class="" @close="$emit('close')">
        <template #modalHeader>
            <h2><span>Aggiungi regola</span></h2>
        </template>
        <template #modalBody>
            <div class="modal-body">
                <!-- RULE NAME -->
                <TextFieldEnh
                    id="rulename"
                    name="rulename"
                    label="Nome regola"
                    placeholder="nome regola"
                    v-model:modelValue="ruleRef.name"
                    :required="true"
                    @update:modelValue="errors.name = false"
                    :error="errors.name"
                    errorMessage="campo obbligatorio"
                ></TextFieldEnh>
                <!-- CHARACTERISTIC -->
                <DropdownList
                    id="rulefeature"
                    name="rulefeature"
                    label="Caratteristica"
                    placeholder="selezionare ..."
                    v-model:value="ruleRef.feature"
                    :options="ruleFeatures"
                    :multiple="false"
                    :required="true"
                    @update:value="errors.feature = false"
                    :error="errors.feature"
                    errorMessage="campo obbligatorio"
                ></DropdownList>
                <!-- SUBJECT -->
                <DropdownList
                    id="rulesubject"
                    name="rulesubject"
                    label="Campo"
                    placeholder="selezionare ..."
                    v-model:value="ruleRef.subject"
                    :options="ruleSubjects"
                    :multiple="false"
                    :required="true"
                    @update:value="
                        errors.subject = false;
                        ruleRef.operator = null;
                    "
                    :error="errors.subject"
                    errorMessage="campo obbligatorio"
                ></DropdownList>
                <!-- OPERATOR -->
                <DropdownList
                    id="ruleoperator"
                    name="ruleoperator"
                    label="Operatore"
                    placeholder="selezionare ..."
                    v-model:value="ruleRef.operator"
                    :options="ruleOperators"
                    :multiple="false"
                    :required="true"
                    @update:value="errors.operator = false"
                    :error="errors.operator"
                    errorMessage="campo obbligatorio"
                ></DropdownList>
                <!-- VALUE -->
                <TextFieldEnh
                    v-if="
                        formEditorStore.getWidgetValueTypeByName(ruleRef.subject) &&
                        formEditorStore.getWidgetValueTypeByName(ruleRef.subject) != 'bool'
                    "
                    id="rulevalue"
                    name="rulevalue"
                    label="Valore"
                    placeholder="valore"
                    v-model:modelValue="ruleRef.value"
                    :required="true"
                    @update:modelValue="errors.value = false"
                    :error="errors.value"
                    errorMessage="campo obbligatorio"
                ></TextFieldEnh>
            </div>
        </template>
        <template #modalFooter>
            <button class="btn-close self-end" type="button" @click.stop="$emit('close')">Annulla</button>
            <button v-if="props.rule === false" class="btn-add self-end" type="button" @click.stop="addRule">Aggiungi</button>
            <button v-if="props.rule !== false" class="btn-add self-end" type="button" @click.stop="saveRule">Salva</button>
        </template>
    </Modal>
</template>

<script setup>
import _ from 'lodash';

import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { features, operators } from '../../data/rules.js';

import { useFormEditorStore } from '../../stores/formEditorStore';

import Modal from '../lib/Modal.vue';
import TextFieldEnh from '../lib/TextFieldEnh.vue';
import DropdownList from '../lib/DropdownList.vue';

const props = defineProps({
    rule: {
        type: [Boolean, Number],
        required: true,
    },
    rules: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits(['update:rules', 'close']);
const rulesRef = useVModel(props, 'rules', emit);

const formEditorStore = useFormEditorStore();

let ruleRef;
ruleRef = ref({
    name: props.rule !== false ? rulesRef.value[props.rule].name : null,
    feature: props.rule !== false ? rulesRef.value[props.rule].feature : null,
    subject: props.rule !== false ? rulesRef.value[props.rule].subject : null,
    operator: props.rule !== false ? rulesRef.value[props.rule].operator : null,
    value: props.rule !== false ? rulesRef.value[props.rule].value : null,
});
const errors = ref({
    name: false,
    feature: false,
    subject: false,
    operator: false,
    value: false,
});

const isFormValid = () => {
    Object.keys(ruleRef.value).forEach((key) => {
        if (!ruleRef.value[key] || ruleRef.value[key] == false) {
            errors.value[key] = true;
        }
    });
    if (Object.keys(errors.value).every((key) => errors.value[key] === false)) return true;
    if (errors.value['value']) {
        if (ruleRef.value.operator == 'is-checked' || ruleRef.value.operator == 'is-not-checked') {
            return true;
        }
    }
    return false;
};

const addRule = () => {
    if (isFormValid()) {
        rulesRef.value.push(ruleRef.value);
        emit('close');
    }
};
const saveRule = () => {
    rulesRef.value.splice(props.rule, 1, ruleRef.value);
    emit('close');
};

const ruleSubjects = computed(() => {
    return _.reduce(
        formEditorStore.formDefIndex,
        (carry, widgetDef) => {
            if (widgetDef.classProperties.type == 'form-widget') {
                carry.push({
                    label: widgetDef.instanceProperties.name,
                });
            }
            return carry;
        },
        []
    );
});

const ruleOperators = computed(() => {
    console.log('OPERATORS: ', operators);
    if (ruleRef.value.subject == null) {
        return [];
    }
    const r = _.filter(operators, (operator) => {
        if (formEditorStore.getWidgetValueTypeByName(ruleRef.value.subject) == operator.type) {
            return true;
        }
        return false;
    });
    return r;
});
const ruleFeatures = computed(() => {
    return features;
});
</script>

<style lang="postcss" scoped>
.modal-body {
    height: auto;
}
</style>
