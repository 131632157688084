<template>
    <div ref="reportWidgetRef" class="template-item-sn">
        <span class="template-item-text-label">1234-123456-123456</span>
    </div>
</template>

<script setup>
import { ref } from 'vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    templateItem: {
        type: Object,
        required: true,
    },
});

const reportWidgetRef = ref();
</script>

<style lang="postcss" scoped>
.template-item-sn {
    @apply font-Urbanist cursor-default;
    @apply p-2;
    @apply block w-full h-full;
}
.template-item-sn:hover {
    @apply cursor-default;
}
</style>
