<template>
    <div class="flex flex-col gap-2">
        <VueDatePicker v-model="valueRef" format="dd/MM/yyyy" auto-apply :enable-time-picker="false"></VueDatePicker>
    </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    pathname: {
        type: Array,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: true,
    },
    readonly: {
        type: Boolean,
        required: true,
    },
    modelValue: {
        type: Date,
        default: () => {
            return new Date();
        },
    },
});
const emit = defineEmits(['update:modelValue']);

const valueRef = useVModel(props, 'modelValue', emit);
</script>

<style lang="postcss" scoped>
.dp__menu.dp__menu_index.dp__theme_light {
    --dp-primary-color: #2f7b64;
    --dp-secondary-color: #2f7b64;
}
</style>
