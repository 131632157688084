<template>
    <div
        ref="reportWidgetRef"
        class="template-item-text"
        :class="{ 'label-over': templateItemRef.instanceProperties.labelPosition == 'over' }"
    >
        <span v-if="templateItemRef.instanceProperties.label != ''" class="template-item-text-label">{{
            templateItemRef.instanceProperties.label
        }}</span>
        <RichTextV3
            ref="richtextRef"
            :id="templateItemRef.instanceProperties.id"
            @dblclick="handleDoubleClick"
            @click="handleClick"
            @focus="console.log('WIDGET FOCUSED')"
            @blur="handleBlur"
            v-model:modelValue="templateItemRef.runtimeProperties.data"
            @update:modelValue="console.log('UPDATE RTE')"
        ></RichTextV3>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { useTemplateStore } from '../../../stores/templateStore';

import RichTextV3 from '../widgets/RichTextV3.vue';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    templateItem: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:templateItem']);

const templateItemRef = useVModel(props, 'templateItem', emit);
const templateStore = useTemplateStore();

if (templateItemRef.value.runtimeProperties.data == null) {
    templateItemRef.value.runtimeProperties.data = '';
}
const reportWidgetRef = ref();

const richtextRef = ref();
const handleDoubleClick = () => {
    console.log('SET FOCUSED', richtextRef.value);
    if (templateItemRef.value.runtimeProperties.selected) {
        templateStore.disableAllPans = true;
    }
    richtextRef.value.setFocused();
};

const handleClick = (ev) => {
    if (richtextRef.value.isFocused) {
        console.log('STOPPED PROPAGATION ON WIDGET REPORT TEXT');
        ev.stopPropagation();
    }
    console.log('CLICK ON WIDGET REPORT TEXT');
};
const handleBlur = () => {
    console.log('BLUR ON WIDGET REPORT TEXT');
    templateStore.disableAllPans = false;
};
</script>

<style lang="postcss" scoped>
.template-item-text {
    @apply cursor-default;
    @apply p-2;
    @apply block w-full h-full;

    &.label-over {
        @apply flex-col;
    }
}
.template-item-text:hover {
    @apply cursor-default;
}
.template-item-text-label {
    @apply font-bold;
}
</style>
