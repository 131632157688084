<template>
    <PropertiesEditorBase
        class="widget-properties-editor-text"
        :name="templateItemTextName"
        :fields="['position', 'dimensions']"
        v-model:templateItem="templateItemRef"
    >
    </PropertiesEditorBase>
</template>

<script setup>
import { useVModel } from '@vueuse/core';

import PropertiesEditorBase from './PropertiesEditorBase.vue';
import { computed } from 'vue';

const props = defineProps({
    templateItem: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:templateItem']);

const templateItemRef = useVModel(props, 'templateItem', emit);
const templateItemTextName = computed(() => {
    return 'Testo';
});
console.log('PROPERTIES EDITOR TEXT: ', templateItemRef.value);
</script>
