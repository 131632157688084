<template>
    <div ref="reportWidgetRef" class="template-item-qrcode">
        <div class="w-full h-full padding7">
            <img src="/assets/images/qrcode-facsimile.png" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useVModel } from '@vueuse/core';

// import QrcodeVue from 'qrcode.vue';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    templateItem: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:templateItem']);

const templateItemRef = useVModel(props, 'templateItem', emit);

if (templateItemRef.value.runtimeProperties.data == null) {
    templateItemRef.value.runtimeProperties.data = '';
}
const reportWidgetRef = ref();

onMounted(() => {});
</script>

<style lang="postcss" scoped>
.template-item-qrcode {
    @apply cursor-default;
    /* @apply p-2; */
    @apply block w-full h-full;
}
.template-item-qrcode:hover {
    @apply cursor-default;
}
.qrcode-container {
    @apply w-full h-full;
    @apply flex justify-center items-center;
}
.qrcode-image {
    @apply w-full h-full;
}
.padding7 {
    padding: 7px;
}
</style>
