<template>
    <PropertiesEditorBase
        class="widget-properties-editor-data"
        :name="templateItemDataName"
        :fields="['label', 'label-position', 'position', 'alignment', 'dimensions', 'font']"
        v-model:templateItem="templateItemRef"
    >
    </PropertiesEditorBase>
</template>

<script setup>
import { useVModel } from '@vueuse/core';

import PropertiesEditorBase from './PropertiesEditorBase.vue';
import { computed } from 'vue';

const props = defineProps({
    templateItem: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:templateItem']);

const templateItemRef = useVModel(props, 'templateItem', emit);
const templateItemDataName = computed(() => {
    return '{ ' + templateItemRef.value.instanceProperties.field.instanceProperties.name + ' }';
});
console.log('PROPERTIES EDITOR DATA: ', templateItemRef.value);
</script>
