<template>
    <div ref="formWidgetRef" class="widget-input-date">
        <div class="widget-input-date-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }} <span v-if="widgetRef.validationProperties.required">*</span></label
            >
            <div class="flex flex-row">
                <DateField
                    v-bind="widget.instanceProperties"
                    v-model:modelValue="widgetRef.runtimeProperties.data"
                    :mode="mode"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                ></DateField>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';
// import { useLoggerStore } from '../../../stores/loggerStore';
import DateField from '../../lib/DateField.vue';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit);
const formWidgetRef = ref();
</script>

<style lang="postcss" scoped>
.widget-input-date {
    @apply border-0;
}
</style>
