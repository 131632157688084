<template>
    <div class="options-editor">
        <span class="font-bold mb-2">Opzioni</span>
        <div class="options-editor-items" v-for="(option, optionIndex) in optionsRef" :key="option">
            <TextFieldEnh name="placeholder" v-model:modelValue="optionsRef[optionIndex].label"></TextFieldEnh>
            <button @click.stop="deleteOption(optionIndex)"><img class="icon-small" src="/assets/icons/delete.svg" /></button>
        </div>
        <button class="options-editor-add-option" @click.stop="addOption">
            <img class="inline-block icon-compact" src="/assets/icons/circle-add.svg" />
            <span> Aggiungi opzione</span>
        </button>
    </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core';

import TextFieldEnh from '../../lib/TextFieldEnh.vue';

const props = defineProps({
    // ogni opzione e' una stringa
    options: {
        type: Array,
        required: true,
    },
});
const emit = defineEmits(['update:options']);

const optionsRef = useVModel(props, 'options', emit);

const deleteOption = (index) => {
    optionsRef.value.splice(index, 1);
};
const addOption = () => {
    optionsRef.value.push({
        label: '',
    });
};
</script>

<style lang="postcss" scoped>
.options-editor {
    @apply flex flex-col;
}
.options-editor-add-option {
    @apply py-3 px-3 flex items-center justify-center gap-1;
    @apply bg-stone-100;
    @apply text-sm font-semibold;
}
.options-editor-items {
    @apply flex gap-2;
}
</style>
