<script setup>
import { useLoaderStore } from './stores/loaderStore';
const loaderStore = useLoaderStore();
</script>

<template>
    <router-view></router-view>
    <div class="loader" v-if="loaderStore.loader">
        <img src="/assets/images/spinner-v2.png" alt="loader" />
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.7;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
