<template>
    <PropertiesEditorBase class="widget-properties-editor-file" name="upload file" :fields="['label']" v-model:widget="widgetRef">
    </PropertiesEditorBase>
</template>

<script setup>
import { useVModel } from '@vueuse/core';
// import { useLoggerStore } from '../../../stores/loggerStore';
import PropertiesEditorBase from './PropertiesEditorBase.vue';

const props = defineProps({
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

const widgetRef = useVModel(props, 'widget', emit);

console.log('PROPERTIES EDITOR FILE: ', widgetRef.value);
</script>
