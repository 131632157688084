<template>
    <div class="widget-properties-editor">
        <div class="properties-editor-title">{{ name }}</div>
        <!-- LABEL -->
        <div v-if="fields.includes('alignment')" class="">
            <div class="w-full text-lg font-bold my-3">
                <span>testo label</span>
            </div>
            <TextFieldEnh
                v-if="fields.includes('label')"
                label=""
                name="inst-label"
                v-model:modelValue="templateItemRef.instanceProperties.label"
            ></TextFieldEnh>
        </div>
        <!-- LABEL POSITION -->
        <!-- <div v-if="fields.includes('label-position')" class="widget-button-group mb-4">
            <div class="w-full text-lg font-bold mb-3">
                <span>posizione label</span>
            </div>
            <div class="widget-button-group-actions">
                <div
                    v-for="(optionLabel, optionValue) in possibleLabelPosition"
                    :key="optionValue"
                    class="flex items-center widget-button-group-action"
                >
                    <input
                        type="radio"
                        :id="'inst-label-pos-' + optionValue"
                        :value="optionValue"
                        v-model="templateItemRef.instanceProperties.labelPosition"
                        class="widget-button-group-state mr-2 hidden"
                        :checked="templateItemRef.instanceProperties.labelPosition == optionValue"
                    />
                    <label :for="'inst-label-pos-' + optionValue">
                        <div class="widget-button-group-button">
                            {{ optionLabel }}
                        </div>
                    </label>
                </div>
            </div>
        </div> -->
        <!-- POSITION -->
        <div v-if="fields.includes('alignment')" class="">
            <div class="w-full text-lg font-bold my-3">
                <span>posizione</span>
            </div>
            <div class="widget-position">
                <!-- LABEL -->
                <TextFieldEnh
                    label=""
                    name="inst-position-x"
                    iconStart="/assets/icons/item-top.svg"
                    iconEnd="/assets/icons/uom-mm.svg"
                    v-model:modelValue="templateItemRef.instanceProperties.x"
                    :readonly="true"
                ></TextFieldEnh>
                <!-- LABEL -->
                <TextFieldEnh
                    label=""
                    name="inst-position-y"
                    iconStart="/assets/icons/item-left.svg"
                    iconEnd="/assets/icons/uom-mm.svg"
                    v-model:modelValue="templateItemRef.instanceProperties.y"
                    :readonly="true"
                ></TextFieldEnh>
            </div>
        </div>
        <!-- DIMENSIONS -->
        <div v-if="fields.includes('dimensions')" class="">
            <div class="w-full text-lg font-bold my-3">
                <span>dimensioni</span>
            </div>
            <div class="widget-dimensions">
                <!-- LABEL -->
                <TextFieldEnh
                    label=""
                    name="inst-dimension-w"
                    iconStart="/assets/icons/item-width.svg"
                    iconEnd="/assets/icons/uom-mm.svg"
                    v-model:modelValue="templateItemRef.instanceProperties.w"
                ></TextFieldEnh>
                <!-- LABEL -->
                <TextFieldEnh
                    label=""
                    name="inst-dimension-h"
                    iconStart="/assets/icons/item-height.svg"
                    iconEnd="/assets/icons/uom-mm.svg"
                    v-model:modelValue="templateItemRef.instanceProperties.h"
                ></TextFieldEnh>
            </div>
        </div>
        <!-- ALIGNMENT -->
        <div v-if="fields.includes('alignment')" class="widget-button-group">
            <div class="w-full text-lg font-bold my-3">
                <span>allineamento</span>
            </div>
            <div class="widget-button-group-actions">
                <div
                    v-for="(optionLabel, optionValue) in possibleAlignments"
                    :key="optionValue"
                    class="flex items-center widget-button-group-action"
                >
                    <input
                        type="radio"
                        :id="'inst-alignment-' + optionValue"
                        :value="optionValue"
                        v-model="templateItemRef.instanceProperties.alignment"
                        class="widget-button-group-state mr-2 hidden"
                        :checked="templateItemRef.instanceProperties.alignment == optionValue"
                    />
                    <label :for="'inst-alignment-' + optionValue">
                        <div class="widget-button-group-button">
                            <img class="icon-small" :src="'/assets/icons/text-align-' + optionValue + '.svg'" />
                        </div>
                    </label>
                </div>
            </div>
            <slot></slot>
        </div>

        <div v-if="fields.includes('font')">
            <div class="w-full text-lg font-bold my-3">
                <span>testo</span>
            </div>
            <div class="widget-font">
                <div class="field-input-container col-span-2">
                    <select class="field-input" name="insta-" v-model="templateItemRef.instanceProperties.font.family">
                        <option value="Urbanist">Urbanist</option>
                        <option value="Roboto">Roboto</option>
                        <option value="NotoSerif">NotoSerif</option>
                        <option value="Lato">Lato</option>
                        <option value="Montserrat">Montserrat</option>
                    </select>
                </div>
                <div class="field-input-container">
                    <select class="field-input" name="insta-" v-model="templateItemRef.instanceProperties.font.weight">
                        <option :value="100">sottile</option>
                        <option :value="400">regular</option>
                        <option :value="700">grassetto</option>
                        <option :value="900">scuro</option>
                    </select>
                </div>
                <div class="">
                    <IntegerField
                        name="insta-font-size"
                        :maxValue="64"
                        :minValue="10"
                        v-model:modelValue="templateItemRef.instanceProperties.font.size"
                    />
                </div>
                <div class="field-input-container shrink">
                    <ColorPicker
                        class="field-input"
                        v-model:pureColor="templateItemRef.instanceProperties.color"
                        :disable-history="false"
                        :disable-alpha="true"
                    >
                    </ColorPicker>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core';

import { ColorPicker } from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';

import TextFieldEnh from '../../lib/TextFieldEnh.vue';
import IntegerField from '../../lib/IntegerField.vue';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    // nomi dele property di cui visualizzare l'editor
    fields: {
        type: Array,
        required: true,
    },
    // current widget
    templateItem: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['update:templateItem']);

const templateItemRef = useVModel(props, 'templateItem', emit);

if (!templateItemRef.value.instanceProperties.alignment) {
    templateItemRef.value.instanceProperties.alignment = 'left';
}
const possibleLabelPosition = {
    before: 'prima',
    sopra: 'sopra',
};
const possibleAlignments = {
    left: 'sinistra',
    center: 'centrato',
    right: 'destra',
    justify: 'giustificato',
};
</script>

<style lang="postcss" scoped>
/* div.widget-properties-editor :deep(.field-label) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.field-input) {
    @apply text-xs;
} */
div.widget-properties-editor :deep(.combobox-input) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.combobox-item) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.combobox-item .icon-small) {
    @apply h-3 w-3;
}
div.widget-properties-editor [type='checkbox'] + :deep(.field-label) {
    @apply mb-0;
}
div.widget-properties-editor :deep(.tags-input-root) {
    @apply h-auto p-[2px];
}
div.widget-properties-editor :deep(.tags-input-item-text) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.tags-input-item-delete .icon-small) {
    @apply h-3 w-3;
}

h2 {
    @apply font-bold my-2;
}
h4 {
    @apply mb-0 mt-4 text-base;
}

.widget-button-group {
    @apply border-0 h-full w-full;
    @apply block;

    .widget-button-group-button {
        @apply px-4 py-2;
        @apply bg-white border-y-2 border-l-2 border-stone-200 border-solid rounded;
    }
    .widget-button-group-actions {
        @apply flex items-center justify-start;
    }
    div.widget-button-group-action:last-child {
        @apply border-r-2 rounded;
    }
    .widget-button-group-state:checked ~ label > .widget-button-group-button {
        @apply bg-stone-200;
    }
}

.widget-position,
.widget-dimensions {
    @apply flex flex-row gap-2;

    :deep(.field-input-container) {
        @apply flex gap-1 items-center px-2;
    }
    :deep(.field-input-icon-start) {
        @apply m-0;
    }
    :deep(.field-input--8-xl-margin) {
        @apply pl-0;
    }
    :deep(.field-input--8-xr-margin) {
        @apply pr-0;
    }
    :deep([name^='inst-label-position-']),
    :deep([name^='inst-label-dimension-']) {
        @apply text-right;
    }
}
.widget-font {
    @apply grid grid-cols-2 gap-2;
}
.field-input-container:has(.vc-color-wrap) {
    @apply items-center;
}
:deep(.vc-color-wrap) {
    @apply w-full mr-0;
}
.properties-editor-title {
    @apply h-16 -mt-4 -ml-4 -mr-4 text-xl text-center py-4 font-bold bg-neutral-light;
    @apply border-b border-solid border-neutral-normal-light;
}
</style>
