<template>
    <div ref="reportWidgetRef" class="template-item-data" :class="classObject">
        <span v-if="templateItemRef.instanceProperties.label" class="template-item-data-label">{{
            templateItemRef.instanceProperties.label
        }}</span>
        <span>{ {{ templateItemRef.instanceProperties.field.instanceProperties.name }} }</span>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    templateItem: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:templateItem']);

const templateItemRef = useVModel(props, 'templateItem', emit);

const reportWidgetRef = ref();

const classObject = computed(() => {
    let res = { 'label-over': templateItemRef.value.instanceProperties.labelPosition == 'over' };
    switch (templateItemRef.value.instanceProperties.alignment) {
        case 'left':
            res['justify-start'] = true;
            break;
        case 'right':
            res['justify-end'] = true;
            break;
        case 'center':
            res['justify-center'] = true;
            break;
        case 'justify':
            res['justify-between'] = true;
            break;
    }
    return res;
});
</script>

<style lang="postcss" scoped>
.template-item-data {
    @apply cursor-default;
    @apply p-2;
    @apply flex flex-row gap-2;

    &.label-over {
        @apply flex-col;
    }
}
.template-item-data:hover {
    @apply cursor-default;
}
.template-item-data-label {
    @apply font-bold;
}
</style>
