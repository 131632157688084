import './bootstrap';

// rende visibile a vite immagini e font di laravel
import.meta.glob(['../images/**', '../fonts/**']);
// usare la macro in blade
// <img src="{{ Vite::asset('resources/images/logo.png') }}">
import '../css/app.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
// import './../css/ckeditor.css';
// import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

import router from './routes/routes';

// import ApiService from './core/services/ApiService';

import WidgetContainer from './components/editor/form-widgets/WidgetContainer.vue';
import WidgetInputText from './components/editor/form-widgets/WidgetInputText.vue';
import WidgetInputNumber from './components/editor/form-widgets/WidgetInputNumber.vue';
import WidgetInputDate from './components/editor/form-widgets/WidgetInputDate.vue';
import WidgetCheckbox from './components/editor/form-widgets/WidgetCheckbox.vue';
import WidgetCheckboxGroup from './components/editor/form-widgets/WidgetCheckboxGroup.vue';
import WidgetRadioGroup from './components/editor/form-widgets/WidgetRadioGroup.vue';
import WidgetButtonGroup from './components/editor/form-widgets/WidgetButtonGroup.vue';
import WidgetRange from './components/editor/form-widgets/WidgetRange.vue';
import WidgetSelect from './components/editor/form-widgets/WidgetSelect.vue';
import WidgetCombobox from './components/editor/form-widgets/WidgetCombobox.vue';
import WidgetImage from './components/editor/form-widgets/WidgetImage.vue';
import WidgetFile from './components/editor/form-widgets/WidgetFile.vue';

// import WidgetReportText from './components/report/report-widgets/WidgetReportText.vue';
import WidgetReportData from './components/report/report-widgets/WidgetReportData.vue';
import WidgetReportText from './components/report/report-widgets/WidgetReportText.vue';
import WidgetReportQrCode from './components/report/report-widgets/WidgetReportQrCode.vue';
import WidgetReportSN from './components/report/report-widgets/WidgetReportSN.vue';

import PropertiesEditorInputText from './components/editor/properties-editors/PropertiesEditorInputText.vue';
import PropertiesEditorInputNumber from './components/editor/properties-editors/PropertiesEditorInputNumber.vue';
import PropertiesEditorInputDate from './components/editor/properties-editors/PropertiesEditorInputDate.vue';
import PropertiesEditorCheckboxGroup from './components/editor/properties-editors/PropertiesEditorCheckboxGroup.vue';
import PropertiesEditorCheckbox from './components/editor/properties-editors/PropertiesEditorCheckbox.vue';
import PropertiesEditorRadioGroup from './components/editor/properties-editors/PropertiesEditorRadioGroup.vue';
import PropertiesEditorButtonGroup from './components/editor/properties-editors/PropertiesEditorButtonGroup.vue';
import PropertiesEditorRange from './components/editor/properties-editors/PropertiesEditorRange.vue';
import PropertiesEditorSelect from './components/editor/properties-editors/PropertiesEditorSelect.vue';
import PropertiesEditorCombobox from './components/editor/properties-editors/PropertiesEditorCombobox.vue';
import PropertiesEditorImage from './components/editor/properties-editors/PropertiesEditorImage.vue';
import PropertiesEditorFile from './components/editor/properties-editors/PropertiesEditorFile.vue';

import PropertiesEditorData from './components/report/properties-editors/PropertiesEditorData.vue';
import PropertiesEditorText from './components/report/properties-editors/PropertiesEditorText.vue';
import PropertiesEditorQrCode from './components/report/properties-editors/PropertiesEditorQrCode.vue';
import PropertiesEditorSN from './components/report/properties-editors/PropertiesEditorSN.vue';

import { useUserInfoStore } from './stores/userInfoStore';
// import ApiService from './services/ApiService';

const app = createApp(App);

app.use(router);
app.use(createPinia());
// app.use(CkeditorPlugin);

const userInfoStore = useUserInfoStore();
userInfoStore.initialize();

// ApiService.init(app);

app.component("WidgetContainer", WidgetContainer);
app.component("WidgetInputText", WidgetInputText);
app.component("WidgetInputNumber", WidgetInputNumber);
app.component("WidgetInputDate", WidgetInputDate);
app.component("WidgetCheckbox", WidgetCheckbox);
app.component("WidgetCheckboxGroup", WidgetCheckboxGroup);
app.component("WidgetRadioGroup", WidgetRadioGroup);
app.component("WidgetButtonGroup", WidgetButtonGroup);
app.component("WidgetRange", WidgetRange);
app.component("WidgetSelect", WidgetSelect);
app.component("WidgetCombobox", WidgetCombobox);
app.component("WidgetImage", WidgetImage);
app.component("WidgetFile", WidgetFile);

// app.component("WidgetReportText", WidgetReportText);
app.component("WidgetReportData", WidgetReportData);
app.component("WidgetReportText", WidgetReportText);
app.component("WidgetReportQrCode", WidgetReportQrCode);
app.component("WidgetReportSN", WidgetReportSN);

app.component("PropertiesEditorInputText", PropertiesEditorInputText);
app.component("PropertiesEditorInputNumber", PropertiesEditorInputNumber);
app.component("PropertiesEditorInputDate", PropertiesEditorInputDate);
app.component("PropertiesEditorCheckbox", PropertiesEditorCheckbox);
app.component("PropertiesEditorCheckboxGroup", PropertiesEditorCheckboxGroup);
app.component("PropertiesEditorRadioGroup", PropertiesEditorRadioGroup);
app.component("PropertiesEditorButtonGroup", PropertiesEditorButtonGroup);
app.component("PropertiesEditorRange", PropertiesEditorRange);
app.component("PropertiesEditorSelect", PropertiesEditorSelect);
app.component("PropertiesEditorCombobox", PropertiesEditorCombobox);
app.component("PropertiesEditorImage", PropertiesEditorImage);
app.component("PropertiesEditorFile", PropertiesEditorFile);

app.component("PropertiesEditorData", PropertiesEditorData);
app.component("PropertiesEditorText", PropertiesEditorText);
app.component("PropertiesEditorQrCode", PropertiesEditorQrCode);
app.component("PropertiesEditorSN", PropertiesEditorSN);

// ApiService.init(app);

app.mount('#app');
